import React, { Fragment } from "react";

const ProjectItem = (props) => {
  return (
    <Fragment>
      <div className='container' style={{ padding: "1%" }}>
        <div className='row'>
          <div className='col s12 m4'>
            <img
              className='responsive-img'
              src={props.image}
              alt='project preview'
              style={{ border: "1.5px solid black" }}
            />
          </div>
          <div div className='col s12 m8'>
            <h3
              style={{
                marginTop: "0px",
              }}
            >
              {" "}
              {props.title}
            </h3>
            <p style={{ fontSize: "1.25em" }}>{props.text}</p>
            <ul>
              <li>
                {" "}
                <a className='link' href={props.github} target="_blank" rel="noopener noreferrer">
                  See it on Github
                </a>
              </li>
              <li>
                <a className='link' href={props.website} target="_blank" rel="noopener noreferrer">
                  Visit Website
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProjectItem;
