import React from "react";
import Ireland from "../images/ireland.jpg";
import "materialize-css/dist/css/materialize.min.css";

const About = () => {
  const containStyle = {
    boxSizing: "border-box",
    fontSize: "1em",
    fontFamily: "Ovo",
   
    overflow: "hidden",
  };

  // const leftSide = {
  //   // maxHeight: "110vh",
  //   fontSize: "2em",
  //   lineHeight: "1.5em",
  //   textAlign: "justify",
  //   borderRadius: "5%",
  //   display: "flex",
  //   alignItems: "center",
  //   marginTop: "auto",
  //   marginBottom: "auto",
  // };

  const imgStyle = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxHeight: "85vh",
  };
  return (
    <div style={containStyle} id='About'>
      <hr
        style={{
          borderTop: "5px dotted black",
          marginTop: "5%",
          width: "50%",
        }}
      ></hr>
      <div className='container' style={{ marginTop: "2%" }}>
        <div className='row'>
          <div className='col s12 m6 leftSide'>
            <p
              style={{
                backgroundColor: "white",
                overflow: "hidden",
                padding: "1em",
                marginTop: "0%",
              }}
            >
              {" "}
              Thanks for stopping by! I'm a self-taught developer coming to tech
              from the primatology field where I was a behavioral researcher and
              caregiver to chimpanzees that use American Sign Language to
              communicate. My unique background allows me to bring a whole
              different set of skills to my work than your average engineer. In
              my spare time you can find me co-organizing my local freeCodeCamp
              San Francisco meetup where I'm helping people get their start with
              coding in a supportive environment and volunteering at NoiseBridge
              Hackerspace. If your company is making the world a better place I
              would love to hear from you.
            </p>
          </div>{" "}
          <div className='col s12 m6'>
            <img
              style={imgStyle}
              className='responsive-img'
              src={Ireland}
              alt='Julie in Ireland'
            />
          </div>
        </div>
        <hr
          style={{
            borderTop: "5px dotted black",
            marginTop: "5%",
            marginBottom: "5%",
            width: "50%",
          }}
        ></hr>
      </div>
    </div>
  );
};
export default About;
