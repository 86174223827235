import React, { Fragment } from "react";

const Navbar = () => {
  return (
    <Fragment>
      <nav>
        <div>
          <ul className='right'>
            <li
              style={{
                fontFamily: "Montserrat !important",
                fontSize: "16px !important",
              }}
            >
              <a href='#About'>About Me</a>
            </li>
            <li>
              <a href='#Skills'>Skills</a>
            </li>
            <li>
              <a href='#Projects'>Projects</a>
            </li>

            <li>
              <a href='#Contact'>Contact</a>
            </li>
          </ul>
        </div>
      </nav>
    </Fragment>
  );
};

export default Navbar;
