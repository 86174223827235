import React, { Fragment } from "react";
import { GiGorilla } from "react-icons/gi";
import { BsCode, BsCodeSlash } from "react-icons/bs";

const Skills = () => {
  const colStyle = {
    // height: "75vh",
  };

  const iconStyle = {
    fontSize: "4em",
    color: " #cb2d6f",
    paddingTop: "5%",
  };

  const h4style = {
    margin: "0",
  };

  return (
    <Fragment>
      <div
        className='container'
        style={{
          backgroundColor: "white",
          boxShadow: "1px 6px 11px -8px rgba(0,0,0,0.75)",
          // height: "80vh",
          // marginTop: "-6%",
          fontFamily: "Montserrat",
          border: ".5px solid gray",
        }}
        id='Skills'
      >
        <div className='row' style={{ textAlign: "center", lineHeight: "2" }}>
          <div
            className='col s12 m4'
            style={(colStyle, { borderRight: " 1px solid #E6ECF8" })}
          >
            <BsCode style={iconStyle} />
            <h4 style={h4style}>Front End</h4>
            <p>My front end skills include but are not limited to:</p>
            <h5 className='pinkh5'>What I know well:</h5>
            <ul>
              <li>HTML</li>
              <li>CSS</li>
              <li>JavaScript</li>
              <li>React</li>
              <li>Git</li>
              <li>Github</li>
            </ul>
            <h5 className='pinkh5'>I'm familiar with:</h5>
            <ul>
              <li>Redux</li>
              <li>Jest</li>
              <li>Bootstrap</li>
              <li>GitLab</li>

            </ul>
          </div>
          <div
            className='col s12 m4'
            style={(colStyle, { borderRight: " 1px solid #E6ECF8" })}
          >
            <BsCodeSlash style={iconStyle} />
            <h4 style={h4style}>Back End</h4>
            <p>My back end development skills include:</p>
            <h5 className='pinkh5'>I'm most familiar with:</h5>
            <ul>
              <li>Node.js</li>
              <li>Express</li>
              <li>Mongoose</li>
              <li>MongoDB</li>
              <li>Heroku</li>
              <li>Netlify</li>
              <li>RESTful APIs</li>
            </ul>
          </div>
          <div className='col s12 m4' style={colStyle}>
            <GiGorilla style={iconStyle} />
            <h4 style={h4style}>Other Skills</h4>
            <p>Chimpanzee caregiver, behavioral researcher</p>
            <h5 className='pinkh5'>I'm also skilled in:</h5>
            <ul>
              <li>Qualitative/Quantitative research</li>
              <li>Written communication</li>
              <li>Data entry and management</li>
              <li>Public speaking</li>
              <li>
                Planning and executing complex caregiving plans with a team of
                30+
              </li>
              <li>Leading research teams</li>
              <li>Training</li>

              <li>Science Communication</li>
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Skills;
