import React from "react";
import { FaLinkedinIn, FaGithub } from "react-icons/fa";

import { GoMail } from "react-icons/go";

const Footer = () => {
  const footerStyle = {
    backgroundColor: "#cb2d6f",
    width: "100vw",
    maxWidth: "100%",
    // height: "20vh",
    fontSize: "1em",
    color: "white",
    fontFamily: "Montserrat",
    overflow: "hidden",
  };
  const iconStyles = {
    fontSize: "2em",
    color: "white",
    padding: "4% !important",
    backgroundColor: "#08231e",
    borderRadius: "20%",
  };
  const iconbutton = {
    backgroundColor: "#08231e",
    border: "none",
    borderRadius: "50%",
    padding: "2%",
    margin: "0%  1% 1% 0%",
  };
  return (
    <div style={footerStyle} id='Contact'>
      <div className='container' style={footerStyle}>
        <div className='row'>
          <div className='col s12 m6'>
            <p style={{ fontSize: "1.75em" }}>Thanks for visiting! </p>
            <p>
              I hope you have a very nice day{" "}
              <span role='img' aria-label='smiley face'>
                &#128578;
              </span>
            </p>
            <p style={{ fontFamily: "Montserrat" }}>
              <small>&copy; Copyright 2020, Julie Boberg</small>
            </p>
          </div>
          <div className='col s12 m6 '>
            <p style={{ fontSize: "1.5em" }}>Let's Connect </p>
            <button style={iconbutton}>
              <a
                href='https://www.linkedin.com/in/julieboberg/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FaLinkedinIn className='icon' style={iconStyles} />
              </a>
            </button>
            {/* <button style={iconbutton}>
              <a
                href='https://twitter.com/julieannboberg'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FaTwitter style={iconStyles} />
              </a>
            </button> */}
            <button style={iconbutton}>
              <a
                href='mailto:JulieAnnBoberg@gmail.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <GoMail style={iconStyles} />
              </a>
            </button>
            <button style={iconbutton}>
              <a
                href='https://github.com/JulieBoberg'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FaGithub style={iconStyles} />
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
