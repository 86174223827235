import React from "react";
import ProjectItem from "./ProjectItem";
import Pdf from "../../resume/ResumeApril2020.pdf";
import Gratitude from "../../images/gratitude.jpg";
import Github from "../../images/github.jpg";

const Project = () => {
  return (
    <div
      style={{
        overflow: "hidden",
        //  maxWidth: "100%"
      }}
    >
      <h2
        style={{
          fontFamily: "Montserrat",
          fontSize: "3.5em",
          paddingTop: "5%",
          marginBottom: "5%",
        }}
        id='Projects'
      >
        Featured Projects
      </h2>
      <div className='container'>
        {" "}
        <ProjectItem
          image={Gratitude}
          text="MoodLift is a web app where users can log the things they're
            grateful for and include a category tag. You can also delete, or edit previous entries. Includes JWT authentication and login functionality.This project uses React (including hooks), Redux,
            Express, Node.js, MongoDB, Materialize frontend framework and deployed on Heroku."
          title='Mood Lift'
          github='https://github.com/JulieBoberg/BeHappy'
          website='https://stark-stream-79138.herokuapp.com/login'
        />
        <ProjectItem
          image={Github}
          text=' A simple web app that allows you to search Github profiles using the
          Github API and React. I utilized useReducer,
          useContext hooks, and the Context API. The project was deployed with Netlify. '
          title='Github Finder '
          github='https://github.com/JulieBoberg/github-finder'
          website='https://juliesgithubfinder.netlify.com/'
        />
      </div>

      <div className='container'>
        <div className='row'>
          <div className='col s1 m4'></div>
          <button
            className='col s10 m4 '
            style={{
              padding: "1% !important",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "16px",
              //width: "20%",
              border: "none",
            }}
          >
            {" "}
            <a
              href='https://github.com/JulieBoberg'
              style={{ fontSize: "1.5em", color: "white" }}
            >
              See more on Github
            </a>
          </button>
          <div className='col s1 m4'></div>
        </div>
        <div className='row'>
          <div className='col s1 m4'></div>

          <button
            className='col s10 m4'
            style={{
              padding: "1% !important",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "5%",
              //maxWidth: "20%",
              border: "none",
            }}
          >
            {" "}
            <a href={Pdf} style={{ fontSize: "1.5em", color: "white" }}>
              Check out my resume{" "}
            </a>
          </button>
          <div className='col s1 m4'></div>
        </div>
      </div>
    </div>
  );
};

export default Project;
