import React, { Fragment } from "react";

const Header = () => {
  const containerStyle = {
    // width: "90vw",
    height: "650px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontFamily: "Montserrat",
  };
  const newstyle = { fontSize: ".5em", fontFamily: "Ovo" };

  return (
    <Fragment>
      <div style={containerStyle}>
        <h1 style={{ fontSize: "5em" }}>
          Hi! I'm &#8202;<span>Julie Boberg</span>
          {"\n"}
          <p style={newstyle}> and I'm a front end developer</p>
        </h1>
      </div>
    </Fragment>
  );
};
export default Header;
